import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { DateTime } from 'luxon';
import { API_URL, APP_VERSION } from 'config';

const axiosConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
  // Make sure objects are serialized into arrays so
  // spatie/laravel-query-builder works. We also filter Luxon DateTimes
  paramsSerializer: (p) => {
    return qs.stringify(p, {
      arrayFormat: 'brackets',
      filter: (prefix: string, value: any): any => {
        return value instanceof DateTime ? value.toISO() : value;
      },
    });
  },
};

// This instance of Axios will not return the user if a reload
// status is received.
const axiosInsecure = Axios.create(axiosConfig);
const axiosInstance = Axios.create(axiosConfig);

const reloadStatuses = [
  401, 403, 419,
];

const attachDctVersion = (request: AxiosRequestConfig) => {
  if (APP_VERSION) {
    request.headers = {
      ...request.headers,
      'x-dct-version': APP_VERSION,
    };
  }

  return request;
};

const refreshIfOld = (response: AxiosResponse) => {
  if (response.headers['x-dct-requires-refresh']) {
    // @ts-ignore true is allowed in Firefox.
    // https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
    window.location.reload(true);
  }

  return response;
};

axiosInsecure.interceptors.request.use(attachDctVersion);
axiosInsecure.interceptors.response.use(refreshIfOld);

axiosInstance.interceptors.request.use(attachDctVersion);
axiosInstance.interceptors.response.use(refreshIfOld, async (error) => {
  if (error.response && reloadStatuses.indexOf(error.response.status) >= 0) {
    window.location.replace('/');
  }

  return Promise.reject(error);
});

export { axiosInsecure, axiosInstance };
